exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agreement-js": () => import("./../../../src/pages/agreement.js" /* webpackChunkName: "component---src-pages-agreement-js" */),
  "component---src-pages-auth-rabotaru-js": () => import("./../../../src/pages/auth/rabotaru.js" /* webpackChunkName: "component---src-pages-auth-rabotaru-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-referral-program-js": () => import("./../../../src/pages/referral-program.js" /* webpackChunkName: "component---src-pages-referral-program-js" */),
  "component---src-pages-tariffs-js": () => import("./../../../src/pages/tariffs.js" /* webpackChunkName: "component---src-pages-tariffs-js" */),
  "component---src-templates-agreement-js": () => import("./../../../src/templates/agreement.js" /* webpackChunkName: "component---src-templates-agreement-js" */)
}

